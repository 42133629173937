export default function Email() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="12"
      viewBox="0 0 14 12"
      fill="none"
    >
      <path
        d="M11.8 1H2.2C1.53726 1 1 1.53726 1 2.2V9.4C1 10.0627 1.53726 10.6 2.2 10.6H11.8C12.4627 10.6 13 10.0627 13 9.4V2.2C13 1.53726 12.4627 1 11.8 1Z"
        stroke="black"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13 2.7998L7.618 6.2198C7.43276 6.33586 7.21859 6.39741 7 6.39741C6.78141 6.39741 6.56724 6.33586 6.382 6.2198L1 2.7998"
        stroke="black"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
