import { Routes, Route } from "react-router-dom";

import Home from "../pages/home";
import Login from "../pages/login";
import Signup from "../pages/sign-up";
import AboutUs from "../pages/about-us";
import Faq from "../pages/faq";
import Blog from "../pages/blog";
import BlogDetail from "../pages/blog-detail";
import Error from "../pages/error";
import BrowseStoryPage from "../pages/browse-story";
import MyLibrary from "../pages/library";
import ProtectedRoute from "./protected-route";

export default function PagesRouter() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/login" element={<Login />} />
      <Route path="/signup" element={<Signup />} />
      <Route path="/about-us" element={<AboutUs />} />
      <Route path="/faq" element={<Faq />} />
      <Route
        path="/my-library"
        element={
          <ProtectedRoute>
            <MyLibrary />
          </ProtectedRoute>
        }
      />
      <Route path="/blog" element={<Blog />} />
      <Route path="/blog/:id" element={<BlogDetail />} />
      <Route path="/stori/:id" element={<BrowseStoryPage />} />
      <Route path="*" element={<Error />} />
    </Routes>
  );
}
