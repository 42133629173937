import feather from "../assets/feather.png";

export default function Loading() {
  return (
    <div className="h-screen bg-primary flex m-auto justify-center items-center flex-col text-white">
      <div className="relative mb-4 lg:mb-[82px]">
        <div className="animate-spinner w-[200px] h-[200px] border border-8 border-links rounded-full"></div>
        <div className="absolute -top-[120px] left-[40px]">
          <img src={feather} alt="Loading" className="h-[400px] object-cover" />
        </div>
      </div>
      <div className="text-xl	lg:text-3xl font-semibold mb-2 lg:mb-4">
        Please wait, while the magic quill crafts your story!
      </div>
      <div className="text-sm	lg:text-xl font-semibold italic">
        (approx. 1 min)
      </div>
    </div>
  );
}
