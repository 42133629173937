import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import Common from "../layout/common";
import MainBg from "../assets/blogs/main.jpg";

export default function AboutUs() {
  const location = useLocation();

  return (
    <Common>
      <Helmet>
        <title>Storicrafter: Crafting Stories That Captivate - About Us</title>
        <link
          rel="canonical"
          href={`https://storicrafter.com${location.pathname}`}
        />
        <meta
          name="description"
          content="Discover a magical world with Storicrafter! Captivating bedtime stories for kids to drift into dreamland happily. Explore enchanting tales every night."
        />
      </Helmet>
      <div className="my-[56px] lg:my-[92px] lg:-mx-[88px] -mx-9">
        <img src={MainBg} alt="About-us" />
      </div>
      <h1 className="text-white text-start text-2xl lg:text-[32px] font-semibold lg:font-bold lg:mx-[210px]">
        About Us
      </h1>
      <div className="text-justify text-sm lg:text-base text-introduction my-[28px] font-light lg:mx-[210px]">
        <h2>Welcome to Our World: A Tapestry of Stories and Dreams!</h2>
        <br />
        We're building a vibrant mosaic of parents, storytellers, technologists,
        and community advocates, all united by a single, powerful belief:
        stories have the magic to transform education, share values, and forge
        unbreakable bonds. As parents, we understand the challenges and joys of
        raising curious, compassionate children in today's fast-paced world.
        We've experienced first-hand how a good story can light up a child's
        imagination, teaching them about life's complexities in a way that's
        both fun and deeply meaningful.
        <br />
        <br /> As storytellers, we cherish the timeless art of weaving tales. We
        believe in the power of narratives to capture hearts, open minds, and
        inspire young souls to dream big. Every story is a journey, and we're
        here to guide your child through enchanting paths filled with wonder and
        learning.
        <br />
        <br />
        As technologists, we're constantly innovating, blending the rich
        tradition of storytelling with cutting-edge technology. This fusion
        allows us to create interactive, engaging experiences that resonate with
        the digital generation, making learning not just effective but truly
        captivating.
        <br />
        <br />
        As community members, we're dedicated to fostering a nurturing
        environment where stories are not just told but shared. We believe in
        the strength of community in shaping the minds of our children,
        supporting each other as we impart values and lessons through our tales.
        <br />
        <br />
        Together, we're more than just a team – we're a family, committed to
        enriching the lives of children through the power and beauty of
        storytelling. Join us on this remarkable journey, where every story
        opens a door to a world of knowledge, imagination, and connection. Let's
        create, share, and grow – one story at a time!
      </div>
    </Common>
  );
}
