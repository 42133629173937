import { useRef, useState } from "react";
import { cn } from "../libs/utils";
import axios from "../libs/axios";
import Close from "../assets/icons/close";
import Email from "../assets/icons/email";
import { isValidEmail } from "../libs/utils";

export default function ShareEmail({ triggerModal }) {
  const email = useRef(null);
  const [error, setError] = useState(null);

  const shareEmail = async () => {
    if (isValidEmail(email.current)) {
      try {
        const response = await axios.post("/junk-mail", {
          email: email.current,
        });
        const {
          data: { error },
        } = response;
        if (error) {
          throw new Error(error);
        }
        localStorage.setItem("email", JSON.stringify(email.current));
        triggerModal(false);
      } catch (error) {
        setError(error.message);
      }
    } else {
      setError("Please use correct email address!");
    }
  };

  return (
    <div className="relative flex flex-col justify-center text-black border border-4 border-links rounded-md p-4 shadow-outter bg-primary lg:max-w-[700px] lg:items-center lg:m-auto">
      <Close
        className="absolute -top-10 right-0 cursor-pointer"
        onClick={() => triggerModal(false)}
      />
      <div className="bg-white px-7 py-[150px] lg:px-24 rounded-md">
        <div className="font-medium text-xl mb-[80px] lg:text-[32px] lg:font-semibold leading-8 text-center">
          Please share your email while the magic quill works on the story!!
        </div>
        <div className="flex flex-col mb-[30px] lg:mb-[40px]">
          <div className="flex items-center mb-[10px]">
            <Email />
            <label
              htmlFor="email"
              className="text-start font-medium text-sm ml-1"
            >
              Email Id<span className="text-red-500">*</span>
            </label>
          </div>
          <input
            name="email"
            type="text"
            placeholder="example@email.com"
            className={cn(
              "flex-1 focus:outline-none bg-control font-light text-control font-medium py-3 px-4 rounded",
              error && "border border-2 border-red-500"
            )}
            onChange={(e) => (email.current = e.target.value)}
          />
          {error && (
            <div className="text-xs text-start text-light text-red-500 my-2">
              {error}
            </div>
          )}
        </div>
        <div
          onClick={shareEmail}
          className="text-center bg-white cursor-pointer py-3 px-14 font-medium rounded-lg bg-gradient-to-r from-[#FF7844] to-[#FF956D] w-2/3 lg:w-1/2 m-auto"
        >
          Submit
        </div>
      </div>
    </div>
  );
}
