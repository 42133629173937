import { NavLink, useNavigate } from "react-router-dom";
import { routes } from "../libs/const";
import FacebookIcon from "../assets/icons/facebook";
import InstagramIcon from "../assets/icons/instagram";
import logo from "../assets/logo.png";
import useBreakpoint from "../hooks/useBreakpoint";

export default function Footer() {
  const breakpoints = useBreakpoint();
  const navigate = useNavigate();
  return (
    <>
      <div className="flex flex-col items-center">
        <img
          src={logo}
          alt="bottom logo"
          className="mb-5 cursor-pointer"
          height={breakpoints === "xs" || breakpoints === "sm" ? 61 : 84}
          width={breakpoints === "xs" || breakpoints === "sm" ? 124 : 164}
          onClick={() => navigate("https://www.storicrafter.com/")}
        />
        <div className="flex-wrap flex gap-7 lg:gap-14 items-center justify-center">
          {routes.map(({ title, route }, index) => (
            <NavLink
              to={`${route}`}
              key={index}
              className={({ isActive }) =>
                isActive
                  ? "text-xs text-primary font-light"
                  : "text-xs text-white font-light"
              }
            >
              {title}
            </NavLink>
          ))}
        </div>
      </div>
      <div className="border border-1 border-gray-500 mb-5 mt-7"></div>
      <div className="flex flex-col lg:flex-row items-center justify-center mb-5">
        <div className="lg:absolute flex gap-2 left-[88px] mb-2 lg:mb-0">
          <NavLink to="https://www.facebook.com/storicrafter">
            <FacebookIcon />
          </NavLink>
          <NavLink to="https://www.instagram.com/storicrafter">
            <InstagramIcon />
          </NavLink>
        </div>
        <div className="flex-wrap flex gap-7 lg:gap-14 items-center justify-center">
          <NavLink
            to="https://app.termly.io/document/terms-of-service/67714c11-aef6-4a82-be27-951ed094db14"
            className={({ isActive }) =>
              isActive
                ? "text-xs text-primary font-light"
                : "text-xs text-copywright font-light"
            }
          >
            Terms of use
          </NavLink>
          <NavLink
            to="https://app.termly.io/document/privacy-policy/38d4d8a0-2f5d-4c8e-8dae-705515f84619"
            className={({ isActive }) =>
              isActive
                ? "text-xs text-primary font-light"
                : "text-xs text-copywright font-light"
            }
          >
            Privacy policy
          </NavLink>
          <NavLink
            to="https://app.termly.io/document/acceptable-use/f288b8eb-3d30-4bc5-8ffd-471bd2c91a72"
            className={({ isActive }) =>
              isActive
                ? "text-xs text-primary font-light"
                : "text-xs text-copywright font-light"
            }
          >
            Acceptable use policy
          </NavLink>
          <NavLink
            to="https://app.termly.io/document/disclaimer/1a3161ea-e0b0-4c4c-a8ad-b4d0e20744d4"
            className={({ isActive }) =>
              isActive
                ? "text-xs text-primary font-light"
                : "text-xs text-copywright font-light"
            }
          >
            Disclaimer
          </NavLink>
        </div>
      </div>
      <div className="text-copywright text-xs	font-light">
        &copy; 2023 by StoriCrafter, storicrafter.com, All rights reserved
      </div>
    </>
  );
}
