import { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import Close from "../assets/icons/close";
import logo from "../assets/logo.png";
import Hamburger from "../assets/icons/hamburger";
import { useAuth } from "../hooks/useAuth";
import { routes } from "../libs/const";
import useBreakpoint from "../hooks/useBreakpoint";

export default function Header() {
  const [openHamburger, setOpenHamburger] = useState(false);
  const breakpoints = useBreakpoint();
  const { logout } = useAuth();
  const navigate = useNavigate();

  return (
    <>
      <div className="flex justify-between">
        <img
          src={logo}
          alt="bg-logo"
          className="cursor-pointer"
          height={breakpoints === "xs" || breakpoints === "sm" ? 107 : 164}
          width={breakpoints === "xs" || breakpoints === "sm" ? 183 : 280}
          onClick={() => navigate("/")}
        />
        <div className="flex text-white self-end">
          <Hamburger
            onClick={() => setOpenHamburger(true)}
            className="cursor-pointer mb-4 lg:hidden"
          />
          <div className="hidden lg:flex gap-[70px] justify-between">
            <NavLink
              to={`https://www.storicrafter.com/`}
              className={({ isActive }) =>
                isActive ? "text-primary font-light" : "font-light"
              }
            >
              Home
            </NavLink>
            <NavLink
              to={`https://www.storicrafter.com/about-us`}
              className={({ isActive }) =>
                isActive ? "text-primary font-light" : "font-light"
              }
            >
              About Us
            </NavLink>
            <NavLink
              to={`https://www.storicrafter.com/faq`}
              className={({ isActive }) =>
                isActive ? "text-primary font-light" : "font-light"
              }
            >
              FAQ
            </NavLink>
            <NavLink
              to={`https://www.storicrafter.com/blog`}
              className={({ isActive }) =>
                isActive ? "text-primary font-light" : "font-light"
              }
            >
              Blog
            </NavLink>
            <NavLink
              to={`https://www.storicrafter.com/my-library`}
              className={({ isActive }) =>
                isActive ? "text-primary font-light" : "font-light"
              }
            >
              My Library
            </NavLink>
            {localStorage.user === "null" ? (
              <NavLink
                to={`/login`}
                className={({ isActive }) =>
                  isActive ? "text-primary font-light" : "font-light"
                }
              >
                Log In
              </NavLink>
            ) : (
              <div
                className="font-light cursor-pointer"
                onClick={() => logout()}
              >
                Log out
              </div>
            )}
          </div>
        </div>
      </div>
      {openHamburger && (
        <div className="absolute right-0 top-10 p-3.5 bg-primary">
          <div className="relative text-white border border-2 border-links rounded-md py-[104px] px-[88px] shadow-outter">
            <Close
              className="absolute top-[30px] right-[14px] cursor-pointer"
              onClick={() => setOpenHamburger(false)}
            />
            <div className="flex flex-col gap-5 items-center">
              {routes.map(({ title, route }, index) => (
                <NavLink
                  to={`${route}`}
                  key={`mobile-${index}`}
                  className={({ isActive }) =>
                    isActive ? "text-primary font-normal" : "font-normal"
                  }
                >
                  {title}
                </NavLink>
              ))}
            </div>
          </div>
        </div>
      )}
    </>
  );
}
