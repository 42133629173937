import { useState, useEffect, useRef } from "react";

import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Pagination } from "swiper/modules";
import Modal from "react-modal";
import { Helmet } from "react-helmet";

import ShareEmail from "../components/share-email";
import Select from "../components/select";
import axios from "../libs/axios";
import { cn } from "../libs/utils";
import { mockGenres } from "../config/vars";
import Story from "./story";
import Loading from "../components/loading";
import Bg from "../assets/bg.png";

// Import Layout components
import Header from "../layout/header";
import Footer from "../layout/footer";

// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";

export default function Home() {
  const inputRef = useRef(null);
  const step2Ref = useRef(null);
  const step3Ref = useRef(null);
  const step4Ref = useRef(null);
  const [gender, setGender] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [userInput, setUserInput] = useState({
    genre: {
      value: null,
      error: false,
    },
    values: {
      value: null,
      error: false,
    },
    names: {
      value: null,
      error: false,
    },
    gender: {
      value: null,
      error: false,
    },
    age: {
      value: null,
      error: false,
    },
  });
  const [names, setNames] = useState();
  const [genreList, setGenreList] = useState([]);
  const [valueList, setValueList] = useState([]);
  const [activeGenre, setActiveGenre] = useState();
  const [activeValue, setActiveValue] = useState();
  const [age, setAge] = useState(0);
  const [loading, setLoading] = useState(false);
  const [story, setStory] = useState(null);
  const [openEmail, setOpenEmail] = useState(false);
  const [empty, setEmpty] = useState(false);

  // const navigate = useNavigate();

  const genStory = async () => {
    Object.entries(userInput).map((item) => {
      const [field, fieldValue] = item;
      if (fieldValue.value) {
        return item;
      } else {
        item[1].error = true;
      }
      setUserInput({ ...userInput, [field]: { ...fieldValue, error: true } });
    });

    if (age && activeGenre && activeValue && gender && names) {
      setEmpty(false);
      let counts = Number(localStorage.getItem("story-count"));
      const isAuthenticated = localStorage.user;
      // if (!counts) {
      //   counts = 1;
      //   setOpenModal(true);
      //   setOpenEmail(true);
      // }
      setLoading(true);
      const payload = {
        genre: activeGenre,
        values: activeValue,
        gender,
        age,
        names,
        counts,
      };
      // if (localStorage.getItem("email")) {
      //   payload.email = JSON.parse(localStorage.getItem("email"));
      // }
      try {
        let storyGenAPI = "/story/free";
        if (isAuthenticated !== "null") {
          storyGenAPI = "/story";
        }
        // if (counts >= 3 && !isActive) {
        //   navigate("/sign-up", { replace: true });
        // }
        const res = await axios.post(storyGenAPI, payload);
        localStorage.setItem("payload", JSON.stringify(payload));
        const genreInfo = genreList.filter((item) => item.id === activeGenre);
        setStory({ genre: genreInfo[0], story: res?.data.story });
        localStorage.setItem("story-count", counts + 1);
      } catch (error) {
        console.log("@@@-error", error);
      }
      setLoading(false);
    } else {
      setEmpty(true);
    }
  };

  // const generateAnother = async () => {
  //   const counts = Number(localStorage.getItem("story-count"));
  //   const payload = JSON.parse(localStorage.getItem("payload"));
  //   let storyGenAPI = "/story/free";
  //   const isActive = JSON.parse(localStorage.getItem("paid"));
  //   if (isActive) {
  //     storyGenAPI = "/story";
  //   }
  //   if (counts >= 3 && !isActive) {
  //     navigate("/sign-up", { replace: true });
  //   }
  //   setLoading(true);
  //   try {
  //     const res = await axios.post(storyGenAPI, payload);
  //     const genreInfo = genreList.filter((item) => item.id === activeGenre);
  //     setStory({ genre: genreInfo[0], story: res?.data.story });
  //     localStorage.setItem("story-count", counts + 1);
  //   } catch (error) {
  //     console.log("Error: ", error);
  //   }
  //   setLoading(false);
  // };

  const setAudienceAge = (selectedAge) => {
    setAge(selectedAge);
    setUserInput({
      ...userInput,
      age: { value: selectedAge, error: false },
    });
  };

  useEffect(() => {
    async function loadGenres() {
      const res = await axios.get("/genre");
      const updatedGenres = mockGenres.map((item) => {
        let ele;
        res?.data.genres.forEach((element) => {
          if (element.title === item.title) {
            ele = { ...item, ...element };
          }
        });
        return ele;
      });
      setGenreList(updatedGenres);
    }
    async function loadValues() {
      const res = await axios.get("/values");
      setValueList(res?.data.values);
    }
    loadGenres();
    loadValues();
  }, []);

  useEffect(() => {
    // console.log(userInput);
  });

  const updateName = () => {
    setUserInput({
      ...userInput,
      names: { value: inputRef.current.value, error: false },
    });
    setNames(inputRef.current.value);
  };

  return (
    <>
      <Helmet>
        <title>
          Children's Short Bedtime Stories Online for Kids | Storicrafter
        </title>
        <link rel="canonical" href="https://storicrafter.com" />
        <meta
          name="description"
          content="Explore enchanting stories for kids' bedtime on Storicrafter. Immerse your child in short, captivating tales that make bedtime a magical adventure."
        />
        <meta
          property="og:title"
          content="Children's Short Bedtime Stories Online for Kids | Storicrafter"
        />
        <meta
          property="og:description"
          content="Explore enchanting stories for kids' bedtime on Storicrafter. Immerse your child in short, captivating tales that make bedtime a magical adventure."
        />
        <meta property="og:url" content="https://storicrafter.com" />
      </Helmet>
      <Modal
        isOpen={openModal}
        className="flex items-center justify-center md:max-w-[700px] h-screen md:m-auto mx-4 outline-none"
      >
        {openEmail && <ShareEmail triggerModal={setOpenModal} />}
      </Modal>
      {loading ? (
        <Loading />
      ) : story ? (
        <Story story={story} />
      ) : (
        <div
          className={cn(
            "relative home bg-primary px-8 py-3 pb-10 lg:px-[88px] lg:pt-[56px] lg:pb-16"
          )}
        >
          <Header />
          <div className="max-w-[1088px] flex flex-col lg:flex-row justify-between mt-24 gap-10 mx-auto mb-[167px]">
            <div className="flex-1">
              <img
                src={Bg}
                alt="background"
                className="w-full h-full aspect-w-16 aspect-h-9"
              />
            </div>
            <div className="flex flex-col gap-4 justify-between w-full lg:w-1/2 text-left text-introduction font-light py-7 px-9 shadow-outter border border-2 lg:border-4 border-links rounded-md">
              <div className="text-sm	lg:text-base">
                Welcome to StoriCrafter, your go-to destination for crafting
                magical stories!
              </div>
              <div className="text-sm	lg:text-base">
                Are you ready to enchant your child's bedtime with a tale that's
                exclusively theirs? Or maybe you're looking to weave values, new
                vocabulary words, or exciting concepts into a story that
                captivates and educates? Look no further!
              </div>
              <div className="text-sm	lg:text-base">
                StoriCrafter elevates story-telling and children's books to
                dazzling new heights. Forget the endless search for the perfect
                book – here, you have the power to create it, tailored just for
                you! And the best part? Unlimited freedom to download, share,
                print, and more! Dive into the world of StoriCrafter and
                transform the way you tell stories. Let's make storytime
                extraordinary!
              </div>
            </div>
          </div>
          <div className="select-genre">
            <div className="text-center text-white mb-[84px]">
              <h1
                className={cn(
                  "mb-3 text-3xl font-semibold py-2",
                  userInput["genre"].error &&
                    "lg:border-2 border-2 border-red-500"
                )}
              >
                1. Select Your Universe
              </h1>
              <h2 className="font-normal	text-introduction">
                Surprise your kids with different themes to tell stories every
                time!
              </h2>
            </div>
            <Swiper
              freeMode={true}
              modules={[FreeMode, Pagination]}
              className="genre-swiper"
              breakpoints={{
                300: {
                  slidesPerView: 1.2,
                  spaceBetween: 20,
                },
                350: {
                  slidesPerView: 1.5,
                  spaceBetween: 20,
                },
                390: {
                  slidesPerView: 1.8,
                  spaceBetween: 20,
                },
                500: {
                  slidesPerView: 2.8,
                  spaceBetween: 30,
                },
                600: {
                  slidesPerView: 3.2,
                  spaceBetween: 30,
                },
                768: {
                  slidesPerView: 4.5,
                  spaceBetween: 40,
                },
                1320: {
                  slidesPerView: 4.7,
                  spaceBetween: 40,
                },
                1420: {
                  slidesPerView: 5,
                  spaceBetween: 30,
                },
                1560: {
                  slidesPerView: 5.5,
                  spaceBetween: 30,
                },
              }}
            >
              {genreList?.map((genre, index) => (
                <SwiperSlide
                  onClick={() => {
                    setUserInput({
                      ...userInput,
                      genre: { value: genre.id, error: false },
                    });
                    setActiveGenre(genre.id);
                    setTimeout(() => {
                      step2Ref.current?.firstElementChild?.scrollIntoView({
                        behavior: "smooth",
                      });
                    }, 300);
                  }}
                  key={genre.id}
                >
                  <div
                    className={cn(
                      "rounded-md p-2 h-[154px] w-[154px] lg:h-[195px] lg:w-[195px] border border-2 lg:border-4 border-links shadow-genreshadow bg-transparent",
                      genre.id === activeGenre &&
                        "shadow-genredrop border-white border-4"
                    )}
                  >
                    <img src={genre.img} alt="Genre item" className="mb-4" />
                    <div className="text-white">
                      <div className="py-3.5 font-semibold">{genre.title}</div>
                      <div className="text-xs	font-light text-introduction">
                        {genre.description}
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
          <div className="choose-values" id="step-2" ref={step2Ref}>
            <div className="text-center text-white mb-[84px]">
              <h3
                className={cn(
                  "mb-3 text-3xl font-semibold py-2",
                  userInput["values"].error &&
                    "lg:border-2 border-2 border-red-500"
                )}
              >
                2. Choose the values you would like to communicate
              </h3>
              <h3 className="font-normal	text-introduction">
                Bedtime stories are great at imparting values and wisdom
              </h3>
            </div>
            <Swiper
              freeMode={true}
              modules={[FreeMode, Pagination]}
              className="values-swiper"
              breakpoints={{
                300: {
                  slidesPerView: 1.2,
                  spaceBetween: 20,
                },
                350: {
                  slidesPerView: 1.5,
                  spaceBetween: 20,
                },
                390: {
                  slidesPerView: 1.8,
                  spaceBetween: 20,
                },
                500: {
                  slidesPerView: 2.8,
                  spaceBetween: 30,
                },
                600: {
                  slidesPerView: 3.2,
                  spaceBetween: 30,
                },
                768: {
                  slidesPerView: 4.5,
                  spaceBetween: 40,
                },
                1320: {
                  slidesPerView: 4.7,
                  spaceBetween: 40,
                },
                1420: {
                  slidesPerView: 5,
                  spaceBetween: 30,
                },
                1560: {
                  slidesPerView: 5.5,
                  spaceBetween: 30,
                },
              }}
            >
              {valueList.map((item) => (
                <SwiperSlide
                  key={item}
                  onClick={() => {
                    setUserInput({
                      ...userInput,
                      values: { value: item, error: false },
                    });
                    setActiveValue(item);
                    setTimeout(() => {
                      step3Ref.current?.firstElementChild?.scrollIntoView({
                        behavior: "smooth",
                      });
                    }, 300);
                  }}
                >
                  <div
                    className={cn(
                      "rounded-full font-semibold text-white flex items-center justify-center h-[154px] w-[154px] lg:h-[165px] lg:w-[165px] border border-2 lg:border-4 border-links shadow-drop bg-transparent",
                      item === activeValue && "shadow-activedrop border-white"
                    )}
                  >
                    {item}
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
          <div
            className="design-character mb-[115px]"
            id="step-3"
            ref={step3Ref}
          >
            <div className="text-center text-white mb-[116px]">
              <h4
                className={cn(
                  "mb-3 text-3xl font-semibold py-2",
                  userInput["names"].error &&
                    "lg:border-2 border-2 border-red-500"
                )}
              >
                3. Design your character
              </h4>
              <h4 className="font-normal text-introduction">
                Kids love it when they are the main character. You may also use
                the name of their pet, stuffed animal, etc. Be creative!
              </h4>
            </div>
            <div className="h-[56px] flex flex-col lg:flex-row justify-center items-center focus:outline-none">
              <input
                type="text"
                placeholder="Name"
                ref={inputRef}
                name="name"
                onChange={updateName}
                className={cn(
                  "text-white bg-transparent focus:outline-none w-3/4 md:w-auto h-full mb-5 lg:mb-0 lg:mr-16 rounded-xl py-4 px-5 border border-2 lg:border-4 border-links shadow-drop font-medium"
                )}
              />
              <div className="flex gap-6">
                <div
                  onClick={() => {
                    setUserInput({
                      ...userInput,
                      gender: { value: "male", error: false },
                    });
                    setGender("male");
                  }}
                  className={cn(
                    "h-[56px] font-bold w-[56px] flex justify-center cursor-pointer items-center text-white rounded-full border border-2 lg:border-4 border-links shadow-drop",
                    gender === "male" && "shadow-genderdrop border-white"
                  )}
                >
                  M
                </div>
                <div
                  onClick={() => {
                    setUserInput({
                      ...userInput,
                      gender: { value: "female", error: false },
                    });
                    setGender("female");
                  }}
                  className={cn(
                    "font-bold w-[56px] flex justify-center cursor-pointer lg:border-4 items-center text-white rounded-full border border-2 border-links shadow-drop",
                    gender === "female" && "shadow-genderdrop border-white"
                  )}
                >
                  F
                </div>
              </div>
            </div>
          </div>
          <div className="choose-ages mb-[300px]" id="step-4" ref={step4Ref}>
            <div className="text-center text-white mb-[84px]">
              <h5
                className={cn(
                  "mb-3 text-3xl font-semibold py-2",
                  userInput["age"].error &&
                    "lg:border-4 border-2 border-red-500"
                )}
              >
                4. Age of audience
              </h5>
              <div className="font-normal	text-base"></div>
            </div>
            <Select onChange={setAudienceAge} />
            <div
              onClick={genStory}
              className="inline-flex bg-white cursor-pointer py-3 px-14 font-medium rounded-lg bg-gradient-to-r from-[#FF7844] to-[#FF956D]"
            >
              Craft Story
            </div>
            {empty && (
              <div className="text-[#F32929] mt-11 lg:text-xl text-sm font-semibold">
                Error: Please complete all required steps before proceeding
              </div>
            )}
          </div>
          <Footer />
        </div>
      )}
    </>
  );
}
