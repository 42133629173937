import FairyTales from "../assets/genres/Fairy Tales.png";
import Holidays from "../assets/genres/Holidays.png";
import DragonTales from "../assets/genres/Dragon Tales.png";
import IndianImagination from "../assets/genres/India.png";
import AfricanLegends from "../assets/genres/African Legends.png";
import AnimalFables2 from "../assets/genres/Animal Fables - 2.png";
import MagicLamp from "../assets/genres/Magic Lamp.png";
import NativeAmerican from "../assets/genres/Native American.png";
import ModernStory from "../assets/genres/Modern Stories.png";

export const values = [
  "Empathy",
  "Respect",
  "Responsibility",
  "Integrity",
  "Perseverance",
  "Kindness",
  "Discipline",
  "Curiosity",
  "Inclusivity",
  "Friendship",
];

export const mockGenres = [
  {
    title: "Fairy Tales",
    description:
      "Inspired by stories of Brothers Grimm, Hans Christian Andersen",
    img: FairyTales,
  },
  {
    title: "Dragon Tales",
    description: "Inspired by traditional children’s stories from Asia",
    img: DragonTales,
  },
  {
    title: "Indian Imagination",
    description:
      "Inspired by traditional children’s stories from Indian subcontinent",
    img: IndianImagination,
  },
  {
    title: "Magic Lamp",
    description: "Inspired by stories from the Arabian nights",
    img: MagicLamp,
  },
  {
    title: "African Legends",
    description: "Inspired by African folktales",
    img: AfricanLegends,
  },
  {
    title: "Animals",
    description: "Inspired by stories from Aesop, Vishnu Sharma",
    img: AnimalFables2,
  },
  {
    title: "Native wisdom",
    description: "Inspired by legends from Native American communities",
    img: NativeAmerican,
  },
  {
    title: "Modern day",
    description: "Stories set in present day, but based on timeless wisdom!",
    img: ModernStory,
  },
  {
    title: "Holidays",
    description: "Stories inspired by Holiday theme!!!",
    img: Holidays,
  },
];

export const breakpoints = {
  0: "xs",
  490: "sm",
  960: "md",
  1280: "lg",
  1920: "xl",
};
