import { Helmet } from "react-helmet";

import Common from "../layout/common";

export default function Error() {
  return (
    <Common>
      <Helmet>
        <title>StoriCrafter | FAQ </title>
        <link rel="canonical" href="https://storicrafter.com/not-found" />
        <meta name="description" content="404 Not Found" />
        <meta property="og:title" content="StoriCrafter | FAQ" />
        <meta property="og:description" content="404 Not Found" />
        <meta property="og:url" content="https://storicrafter.com/not-found" />
      </Helmet>
      <div className="flex flex-col justify-center items-center text-black border border-4 border-links rounded-md p-4 shadow-outter bg-primary lg:items-center my-14">
        <div className="bg-white rounded-md inline-flex flex-col gap-4 w-full p-4">
          <div className="text-black text-4xl text-center font-bold leading-9">
            Ooops! something went wrong.
          </div>
          <div className="lg:my-8 my-4 text-center font-semibold lg:font-bold text-2xl">
            please email us at{" "}
            <span className="text-blue-500">hello@storicrafter.com</span> and we
            will be happy to assit you!!
          </div>
        </div>
      </div>
    </Common>
  );
}
