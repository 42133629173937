import Blog1 from "../assets/blogs/blog1.webp";
import Blog2 from "../assets/blogs/blog2.webp";
import Blog3 from "../assets/blogs/blog3.webp";
import Blog4 from "../assets/blogs/blog4.webp";
import Blog5 from "../assets/blogs/blog5.webp";
import Blogmd1 from "../markdown/1.md";
import Blogmd2 from "../markdown/2.md";
import Blogmd3 from "../markdown/3.md";
import Blogmd4 from "../markdown/4.md";
import Blogmd5 from "../markdown/5.md";

export const routes = [
  {
    title: "Home",
    route: "/",
  },
  {
    title: "About Us",
    route: "/about-us",
  },
  {
    title: "FAQ",
    route: "/faq",
  },
  {
    title: "Blog",
    route: "/blog",
  },
  {
    title: "My Library",
    route: "/my-library",
  },
  {
    title: "Log In",
    route: "/login",
  },
];

export const blogs = [
  {
    id: "the-magic-of-bedtime-stories",
    title: "The magic of bedtime stories",
    summary: `Bedtime is a precious winding down period for both parents and children. As the day's activities come to a close, there's no better way to create a sense of warmth and bonding than by sharing a stor...`,
    img: Blog1,
    metaTitle: "Discover the Magic of Kid Bedtime Stories | Storicrafter Blog",
    metaDesc:
      "Immerse your child in enchanting bedtime tales. Explore the world of kid bedtime stories on Storicrafter’s blog for a magical bedtime routine.",
    markdown: Blogmd1,
  },
  {
    id: "how-to-create-bedtime-stories-that-capture-childrens-imaginations",
    title: "How To Create Bedtime Stories That Capture Children’s Imaginations",
    summary: `Bedtime is a magical time for letting young imaginations run wild. As a parent, and coming from a family that values storytelling, I've learned the key ingredients for crafting bedtime stories that...`,
    img: Blog2,
    metaTitle:
      "Crafting Captivating Bedtime Stories for Kids | StoriCrafter Blog",
    metaDesc:
      "Discover the art of crafting captivating bedtime stories that spark children's imaginations. Explore tips and ideas to create magical tales for bedtime bliss.",
    markdown: Blogmd2,
  },
  {
    id: "the-meta-messages-from-grimm-brothers-fairy-tales",
    title: "The Meta Messages from Grimm Brothers Fairy Tales",
    summary: `As a parent, I often think about the stories we share with children and the lessons they impart. The Grimm Brothers fairy tales I read growing up sent messages not just about fantasy, imagination...`,
    img: Blog3,
    metaTitle: "Unlocking Meta Messages in Grimm Brothers' Fairy Tales",
    metaDesc:
      "Delve into the hidden meanings and meta messages within Grimm Brothers' fairy tales. Explore intriguing insights and interpretations of the timeless stories.",
    markdown: Blogmd3,
  },
  {
    id: "why-telling-your-kids-a-story-is-a-good-supplement-to-reading-them",
    title:
      "Why Telling Your Kids a Story is a Good Supplement to Reading to Them",
    summary: `As parents and caregivers, we know how important it is to read to our children. Reading fosters language development, expands their understanding of the world, and helps bond us together. However...`,
    img: Blog4,
    metaTitle: "Unlocking Imagination: The Power of Storytelling for Kids",
    metaDesc:
      "Discover why telling your kids a story is a valuable supplement to reading. Uncover the benefits of storytelling & enhance your child's imagination and learning.",
    markdown: Blogmd4,
  },
  {
    id: "fantastic-fables-5-unique-characters-from-stories-around-the-globe",
    title:
      "Fantastic Fables: 5 Unique Characters from Stories Around the Globe",
    summary: `Folk tales and myths from around the world are filled with unforgettable characters that have been passed down from generation to generation. As an early childhood educator, I'm always looking for ...`,
    img: Blog5,
    metaTitle:
      "Discover Magical Characters: Fantastic Fables from Around the World |",
    metaDesc:
      "Embark on a journey of wonder as we introduce 5 unique characters from fantastic fables around the globe. Discover the rich diversity of storytelling and folklore.",
    markdown: Blogmd5,
  },
];
