import { Navigate } from "react-router-dom";
// import { useAuth } from "../hooks/useAuth";

export default function ProtectedRoute({ children }) {
  const result =
    localStorage.user !== "null" ? children : <Navigate to="/login" />;
  return result;
  // const { user } = useAuth();
  // if (!user) {
  //   return <Navigate to="/login" />
  // }
  // return children;
}
