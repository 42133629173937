import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Tooltip } from "react-tooltip";

import { cn } from "../libs/utils";
import Remove from "../assets/icons/remove";
import Share from "../assets/icons/share";
import { mockGenres } from "../config/vars";

export default function LibraryItem({
  type = "your_story",
  selectedIndex,
  category,
  stories,
  onRemove,
}) {
  const [genreLogo, setGenreLogo] = useState(null);
  useEffect(() => {
    if (selectedIndex === 0) {
      const [genreItem] = mockGenres.filter(
        (genre) => genre.title === category
      );
      setGenreLogo(genreItem?.img);
    }
  }, []);

  return (
    <>
      {stories.map((story, index) => (
        <div
          className={cn(
            "flex gap-2 lg:gap-16 h-[74px] lg:h-[196px]",
            selectedIndex && "h-[120px]"
          )}
          key={index}
        >
          <div
            className={cn(
              "w-[74px] lg:w-[196px]",
              selectedIndex && "w-[120px]"
            )}
          >
            {selectedIndex === 0 && (
              <img src={genreLogo} alt="Library_Logo" className="rounded" />
            )}
            {selectedIndex === 1 && (
              <div className="text-center h-full w-full rounded-full font-semibold text-white flex items-center justify-center border border-2 lg:border-4 border-links shadow-drop bg-transparent">
                {category}
              </div>
            )}
            {selectedIndex === 2 && (
              <div className="text-center h-full w-full font-semibold text-primary text-2xl lg:text-4xl flex items-center justify-center bg-transparent">
                {category}
              </div>
            )}
          </div>
          <div className="flex-1 flex gap-2 overflow-hidden">
            <div className="flex flex-col gap-1 w-3/4 lg:w-2/3 pb-3 lg:py-3">
              <Link to={`https://www.storicrafter.com/stori/${story?._id}`}>
                <div className="text-sm font-semibold lg:font-semibold lg:text-2xl">
                  {story?.content.substring(
                    story?.content.indexOf(":") + 2,
                    story?.content.indexOf("\n\n")
                  )}
                </div>
              </Link>
              <div className="text-xs font-light lg:text-base text-introduction">
                {story?.content.substring(story?.content.indexOf("\n\n") + 1)}
              </div>
            </div>
            <div className="flex flex-1 gap-2 items-end justify-center lg:pb-8 pb-4">
              {type !== "recommended" && (
                <Remove
                  className="cursor-pointer"
                  onClick={() => onRemove(story._id)}
                />
              )}
              <CopyToClipboard text={`storicrafter.com/stori/${story?._id}`}>
                <Share
                  className="cursor-pointer"
                  data-tooltip-id="share-tooltip"
                />
              </CopyToClipboard>
            </div>
          </div>
          <Tooltip
            id="share-tooltip"
            content="Copied!"
            style={{ backgroundColor: "rgb(220 237 118)", color: "#222" }}
            openOnClick={true}
          />
        </div>
      ))}
    </>
  );
}
