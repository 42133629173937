import useBreakpoint from "../../hooks/useBreakpoint";

export default function Remove({ className, ...props }) {
  const breakpoints = useBreakpoint();
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={breakpoints === "xs" || breakpoints === "sm" ? "20" : "36"}
      height={breakpoints === "xs" || breakpoints === "sm" ? "20" : "36"}
      viewBox="0 0 36 36"
      fill="none"
      className={className}
      {...props}
    >
      <path
        d="M4.5 9H31.5"
        stroke="white"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M28.5 9V30C28.5 31.5 27 33 25.5 33H10.5C9 33 7.5 31.5 7.5 30V9"
        stroke="white"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 9V6C12 4.5 13.5 3 15 3H21C22.5 3 24 4.5 24 6V9"
        stroke="white"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15 16.5V25.5"
        stroke="white"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21 16.5V25.5"
        stroke="white"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
