import { useEffect } from "react";
import { BrowserRouter } from "react-router-dom";

import PagesRouter from "./router";
import { useLocalStorage } from "./hooks/useLocalStorage";
import { setAuth } from "./libs/set-auth";

import { AuthProvider } from "./hooks/useAuth";

import "./App.css";

function App() {
  const [user, setUser] = useLocalStorage("user", null);
  useEffect(() => {
    if (user) {
      setUser(user);
      setAuth(user);
    }
  });
  return (
    <div className="App w-full">
      <BrowserRouter>
        <AuthProvider>
          <PagesRouter />
        </AuthProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;
