import { useEffect, useState, useRef } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import axios from "../libs/axios";
import { setAuth } from "../libs/set-auth";
import Common from "../layout/common";
import UserProfile from "../assets/icons/profile";
import KeyIcon from "../assets/icons/key";
import ClosedEye from "../assets/icons/closedeye";
import OpenEye from "../assets/icons/openeye";
import { jwtDecode } from "jwt-decode";
import { useLocalStorage } from "../hooks/useLocalStorage";

export default function Signup() {
  const [user, setUser] = useLocalStorage("user", null);
  const [remember, setRemember] = useState(false);
  const [error, setError] = useState(null);
  const [show, setShow] = useState(false);
  const navigate = useNavigate();
  const emailRef = useRef();
  const nameRef = useRef();
  const passwordRef = useRef();

  let [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    console.log(searchParams.get("email"));
    if (searchParams.get("email")) {
      emailRef.current.value = searchParams.get("email");
    }
  });

  const handleSignup = async () => {
    if (remember && emailRef.current && passwordRef.current) {
      const payload = {
        name: nameRef.current.value,
        email: emailRef.current.value,
        password: passwordRef.current.value,
      };
      try {
        const mailerRes = await axios.post("/mailerlite/add-to-signed", {
          email: emailRef.current.value,
        });
        console.log(mailerRes);
        const res = await axios.post("/auth/register", payload);
        setUser(res?.data.token);
        setAuth(res?.data.token);
        const { isActive } = jwtDecode(res?.data.token);
        localStorage.setItem("paid", JSON.stringify(isActive));
        navigate("/", { replace: true });
      } catch (error) {
        setError("Duplicated email or username");
      }
    }
  };
  return (
    <Common>
      <Helmet>
        <title>StoriCrafter | SignUp </title>
        <link rel="canonical" href="https://storicrafter.com/login" />
        <meta name="description" content="Login or Sign up" />
        <meta property="og:title" content="StoriCrafter | SignUp" />
        <meta property="og:description" content="Login or Sign up" />
        <meta property="og:url" content="https://storicrafter.com/sign-up" />
      </Helmet>
      <div className="lg:w-1/3 md:w-2/3 m-auto">
        <div className="text-start mt-20 lg:mt-44">
          <div className="text-white font-semibold text-2xl mb-2.5 lg:text-[32px] lg:mb-1">
            Sign Up
          </div>
        </div>
        <div className="my-16">
          {error && (
            <div className="text-lg font-bold text-red-500 mb-8">{error}</div>
          )}
          <div className="flex py-4 px-7 rounded-lg items-center bg-control mb-7">
            {/* <UserProfile className="mr-3" /> */}
            <input
              type="email "
              placeholder="my@email.com"
              className="flex-1 focus:outline-none bg-transparent font-light text-control font-medium"
              ref={emailRef}
            />
          </div>
          <div className="flex py-4 px-7 rounded-lg items-center bg-control mb-7">
            {/* <UserProfile className="mr-3" /> */}
            <input
              type="text"
              placeholder="First Name"
              className="flex-1 focus:outline-none bg-transparent font-light text-control font-medium"
              ref={nameRef}
            />
          </div>
          <div className="flex py-4 px-7 rounded-lg items-center bg-control">
            {/* <KeyIcon className="mr-3" /> */}
            <input
              type={show ? "text" : "password"}
              placeholder="Password"
              className="flex-1 focus:outline-none bg-transparent font-light text-control font-medium"
              ref={passwordRef}
            />
            <div onClick={() => setShow(!show)}>
              {show ? <OpenEye /> : <ClosedEye />}
            </div>
          </div>
          <div className="flex flex-col gap-4 my-[70px]">
            <div
              className="flex items-center justify-start cursor-pointer"
              onClick={() => setRemember(!remember)}
            >
              {remember ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="25"
                  height="20"
                  viewBox="0 0 25 20"
                  fill="none"
                >
                  <rect
                    x="0.5"
                    y="0.5"
                    width="19"
                    height="19"
                    rx="2.5"
                    stroke="white"
                  />
                  <path
                    d="M23 2L12 13L7 8"
                    stroke="#FF956D"
                    strokeWidth="3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="25"
                  height="20"
                  viewBox="0 0 25 20"
                  fill="none"
                >
                  <rect
                    x="0.5"
                    y="0.5"
                    width="19"
                    height="19"
                    rx="2.5"
                    stroke="white"
                  />
                </svg>
              )}
              <span className="ml-3 text-white font-semibold text-sm lg:text-base">
                I accept all{" "}
                <Link
                  to="https://app.termly.io/document/terms-of-service/67714c11-aef6-4a82-be27-951ed094db14"
                  className="underline underline-offset-8 h-1"
                >
                  terms & conditions
                </Link>
              </span>
            </div>
            <div
              onClick={handleSignup}
              className="bg-white cursor-pointer py-3 px-14 font-medium rounded-lg bg-gradient-to-r from-[#FF7844] to-[#FF956D]"
            >
              Sign Up
            </div>
          </div>
          <div className="flex flex-col gap-8">
            <div className="text-start">
              <div className="mb-1 text-white font-semibold text-sm lg:text-xs">
                Fogot Password?
              </div>
              <div className="text-xs text-introduction">
                Email us at{" "}
                <span className="underline">hello@storicrafter.com</span> and we
                will reset it for you within one business day
              </div>
            </div>
            <div className="text-start">
              <div className="mb-1 text-white font-semibold text-sm lg:text-xs">
                Looking to cancel?
              </div>
              <div className="text-xs text-introduction">
                Sorry to see you go! Please drop us a note at{" "}
                <span className="underline">hello@storicrafter.com</span> and we
                will cancel same day
              </div>
            </div>
          </div>
        </div>
      </div>
    </Common>
  );
}
