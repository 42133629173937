import useBreakpoint from "../../hooks/useBreakpoint";

export default function Share({ className, ...props }) {
  const breakpoints = useBreakpoint();
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={breakpoints === "xs" || breakpoints === "sm" ? "20" : "36"}
      height={breakpoints === "xs" || breakpoints === "sm" ? "20" : "36"}
      viewBox="0 0 32 32"
      fill="none"
      className={className}
      {...props}
    >
      <path
        d="M24 10.667C26.2091 10.667 28 8.87613 28 6.66699C28 4.45785 26.2091 2.66699 24 2.66699C21.7909 2.66699 20 4.45785 20 6.66699C20 8.87613 21.7909 10.667 24 10.667Z"
        stroke="white"
        strokeWidth="2.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 20C10.2091 20 12 18.2091 12 16C12 13.7909 10.2091 12 8 12C5.79086 12 4 13.7909 4 16C4 18.2091 5.79086 20 8 20Z"
        stroke="white"
        strokeWidth="2.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24 29.333C26.2091 29.333 28 27.5421 28 25.333C28 23.1239 26.2091 21.333 24 21.333C21.7909 21.333 20 23.1239 20 25.333C20 27.5421 21.7909 29.333 24 29.333Z"
        stroke="white"
        strokeWidth="2.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.4531 18.0127L20.5598 23.3194"
        stroke="white"
        strokeWidth="2.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.5465 8.67969L11.4531 13.9864"
        stroke="white"
        strokeWidth="2.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
