import { Link, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import Common from "../layout/common";
import { blogs } from "../libs/const";

export default function Blog() {
  const location = useLocation();

  return (
    <Common>
      <Helmet>
        <title>
          Discover Magical Stories for Kids to Sleep - Storicrafter Blog{" "}
        </title>
        <link rel="canonical" href="https://storicrafter.com/blog" />
        <meta
          name="description"
          content="Explore enchanting bedtime stories for children on Storicrafter's blog. Immerse your little ones in captivating tales for a peaceful night's sleep."
        />
        <meta
          property="og:title"
          content="Discover Magical Stories for Kids to Sleep - Storicrafter Blog"
        />
        <meta
          property="og:description"
          content="Explore enchanting bedtime stories for children on Storicrafter's blog. Immerse your little ones in captivating tales for a peaceful night's sleep."
        />
        <meta property="og:url" content="https://storicrafter.com/blog" />
      </Helmet>
      <h1 className="text-white text-start text-2xl mt-20 lg:mt-44 lg:text-[32px] font-semibold lg:font-bold">
        Blog Posts & Articles
      </h1>
      <div className="grid grid-cols-2 lg:grid-cols-3 gap-4 my-10 md:gap-8 lg:gap-10">
        {blogs.map((blog, index) => (
          <Link
            to={`/blog/${blog.id}`}
            key={`blog-${index}`}
            className="flex flex-col lg:justify-between px-3.5 lg:p-6 py-5 border border-2 border-links rounded-md shadow-outter cursor-pointer"
          >
            <img
              src={blog.img}
              alt="blog_image"
              className="rounded-lg mb-4 lg:mb-0"
            />
            <div className="text-white text-start font-medium text-base lg:font-bold lg:text-xl lg:my-4 lg:h-14 line-clamp-2">
              {blog.title}
            </div>
            <div className="text-start text-introduction text-base font-light	hidden lg:block">
              {blog.summary}
            </div>
          </Link>
        ))}
      </div>
    </Common>
  );
}
