import { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { useLocalStorage } from "../hooks/useLocalStorage";
import { setAuth } from "../libs/set-auth";

import axios from "../libs/axios";
import { cn } from "../libs/utils";
import LibraryItem from "../components/library-item";
import Common from "../layout/common";

const tabLists = ["genre", "values", "names"];

export default function Library() {
  const [user, setUser] = useLocalStorage("user", null);
  const [selectedIndex, setIndex] = useState(0);
  const [loading, setLoading] = useState(false);

  const [libraryList, setLibraryList] = useState({
    genre: [],
    values: [],
    names: [],
  });
  const [recommendedList, setRecommendedList] = useState({
    genre: [],
    values: [],
  });

  const onRemove = async (storyId) => {
    setLoading(true);
    await axios.put(`/story/${storyId}/remove-to-library`);
    setLoading(false);
  };

  useEffect(() => {
    if (user) {
      setUser(user);
      setAuth(user);
    }
  });

  useEffect(() => {
    async function loadContentsbyIndex() {
      const res = await axios.get(`/story?group=${tabLists[selectedIndex]}`);
      setLibraryList({
        ...libraryList,
        [tabLists[selectedIndex]]: res?.data?.story,
      });
    }

    async function loadRecommendedStories() {
      const res = await axios.get(
        `/story/random?group=${tabLists[selectedIndex]}`
      );
      setRecommendedList({
        ...recommendedList,
        [tabLists[selectedIndex]]: res?.data?.story,
      });
    }

    loadContentsbyIndex();
    loadRecommendedStories();
  }, [selectedIndex, loading]);

  return (
    <Common>
      <Helmet>
        <title>StoriCrafter | My Library </title>
        <link rel="canonical" href="https://storicrafter.com/my-library" />
        <meta name="description" content="My Library" />
        <meta property="og:title" content="StoriCrafter | My Library" />
        <meta property="og:description" content="My Library" />
        <meta property="og:url" content="https://storicrafter.com/my-library" />
      </Helmet>
      <div className="bg-library py-5 -mx-9 lg:-mx-[88px] px-14 lg:py-12 my-8 lg:my-[70px]">
        <div className="font-bold text-xl text-genre lg:text-[32px] lg:mb-3.5">
          Welcome To Your Library
        </div>
        <div className="font-medium text-xs text-genre lg:text-base">
          Here you can find stories sorted by Universe, Values and Lead
          character
        </div>
      </div>
      <Tabs
        defaultIndex={selectedIndex}
        onSelect={(index) => setIndex(index)}
        className="my-8"
      >
        <TabList className="flex justify-between">
          <Tab
            className={cn(
              "lg:text-2xl py-2 outline-none flex-1 text-sm text-white font-bold cursor-pointer",
              selectedIndex === 0 && "border-primary border-b-2 text-primary"
            )}
          >
            Universe
          </Tab>
          <Tab
            className={cn(
              "lg:text-2xl py-2 outline-none flex-1 text-sm text-white font-bold cursor-pointer",
              selectedIndex === 1 && "border-primary border-b-2 text-primary"
            )}
          >
            Values
          </Tab>
          <Tab
            className={cn(
              "lg:text-2xl py-2 outline-none flex-1 text-sm text-white font-bold cursor-pointer",
              selectedIndex === 2 && "border-primary border-b-2 text-primary"
            )}
          >
            Lead Character
          </Tab>
        </TabList>

        <TabPanel className="text-white text-start">
          <div className="text-white text-start mt-16 mb-12">
            <h2 className="font-semibold mb-12 lg:text-[32px]">
              Stories you crafted
            </h2>
            <div className="flex flex-col gap-4 lg:gap-10">
              {libraryList.genre.length > 0 ? (
                libraryList.genre?.map((universe, index) => (
                  <LibraryItem
                    selectedIndex={selectedIndex}
                    key={index}
                    category={universe._id}
                    stories={universe.stories}
                    onRemove={onRemove}
                  />
                ))
              ) : (
                <h1>You have no stories in your library</h1>
              )}
            </div>
          </div>
          <div className="text-white text-start mt-16 mb-12">
            <h2 className="font-semibold mb-4 lg:text-[32px] lg:mb-7">
              Stories we think you might like!
            </h2>
            <div className="text-xs text-introduction lg:text-xl">
              It’s always a great idea to explore different universes of
              stories. Imagine the same values, your favourite lead character in
              different universes!
            </div>
          </div>
          <div className="flex flex-col gap-4 lg:gap-10">
            {recommendedList.genre.length > 0 ? (
              recommendedList.genre?.map((universe, index) => (
                <LibraryItem
                  type="recommended"
                  selectedIndex={0}
                  key={index}
                  category={universe._id}
                  stories={universe.stories}
                  onRemove={onRemove}
                />
              ))
            ) : (
              <h1>You have no stories in your library</h1>
            )}
          </div>
        </TabPanel>
        <TabPanel className="text-white text-start">
          <div className="text-white text-start mt-16 mb-12">
            <h2 className="font-semibold mb-12 lg:text-[32px]">
              Stories you crafted
            </h2>
            <div className="flex flex-col gap-4 lg:gap-10">
              {libraryList.values.length > 0 ? (
                libraryList.values?.map((universe, index) => (
                  <LibraryItem
                    selectedIndex={selectedIndex}
                    key={index}
                    category={universe._id}
                    stories={universe.stories}
                    onRemove={onRemove}
                  />
                ))
              ) : (
                <h1>You have no stories in your library</h1>
              )}
            </div>
          </div>
          <div className="text-white text-start mt-16 mb-12">
            <h2 className="font-semibold mb-4 lg:text-[32px] lg:mb-7">
              Stories we think you might like!
            </h2>
            <div className="text-xs text-introduction lg:text-xl">
              Reinforcing core values is critical part of early childhood years.
            </div>
          </div>
          <div className="flex flex-col gap-4 lg:gap-10">
            {recommendedList.values.length > 0 ? (
              recommendedList.values?.map((universe, index) => (
                <LibraryItem
                  type="recommended"
                  selectedIndex={1}
                  key={index}
                  category={universe._id}
                  stories={universe.stories}
                  onRemove={onRemove}
                />
              ))
            ) : (
              <h1>You have no stories in your library</h1>
            )}
          </div>
        </TabPanel>
        <TabPanel className="text-white text-start">
          <div className="text-white text-start mt-16 mb-12">
            <h2 className="font-semibold mb-12 lg:text-[32px]">
              Stories you crafted
            </h2>
            <div className="flex flex-col gap-4 lg:gap-10">
              {libraryList.names.length > 0 ? (
                libraryList.names?.map((universe, index) => (
                  <LibraryItem
                    selectedIndex={selectedIndex}
                    key={index}
                    category={universe._id}
                    stories={universe.stories}
                    onRemove={onRemove}
                  />
                ))
              ) : (
                <h1>You have no stories in your library</h1>
              )}
            </div>
          </div>
          <div className="text-white text-start mt-16 mb-12">
            <h2 className="font-semibold mb-4 lg:text-[32px] lg:mb-7">
              Suggestions for other lead characters:
            </h2>
            <ul className="text-xs text-introduction lg:text-xl list-disc">
              <li className="mb-2">
                <span className="font-bold text-white">Stuffed pets : </span>
                Children love hearing stories about their stuffed pets as they
                traverse different universes!
              </li>
              <li className="mb-2">
                <span className="font-bold text-white">
                  Friends, cousins, etc :{" "}
                </span>
                Consider creating a story with your child’s friend as the main
                character and share with them!
              </li>
              <li className="mb-2">
                <span className="font-bold text-white">Sibling : </span>Stories
                about their Siblings are often very exciting for young children!
              </li>
            </ul>
          </div>
        </TabPanel>
      </Tabs>
    </Common>
  );
}
