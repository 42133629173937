import { useState, useEffect } from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Tooltip } from "react-tooltip";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Feather from "../assets/feather.png";
import Common from "../layout/common";
import { useLocalStorage } from "../hooks/useLocalStorage";

import axios from "../libs/axios";
import { mockGenres } from "../config/vars";

export default function BrowseStoryPage() {
  const navigate = useNavigate();
  const [user, setUser] = useLocalStorage("user", null);

  const { id } = useParams();
  const [story, setStory] = useState(null);
  const [title, setTitle] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [genreImage, setGenreImage] = useState("");
  const location = useLocation();

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const res = await axios.get(`/story/${id}`);
        const rawStory = res?.data.story.content;

        setTitle(
          rawStory.substring(
            rawStory.indexOf(":") + 2,
            rawStory.indexOf("\n\n")
          )
        );

        setStory(res?.data.story);
        const genreObj = mockGenres.filter(
          (item) => item.title === res?.data.story.genre
        );
        setGenreImage(genreObj[0].img);
      } catch (error) {
        console.log("ERR", error);
        setError(error);
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  const shareStory = () => {
    console.log(story?.id);
  };

  const saveToLibrary = async () => {
    if (user === "null") {
      navigate("/login");
    } else {
      await axios.put(`story/${story.id}`);
    }
  };

  return (
    <Common>
      <Helmet>
        <title>{title}</title>
        <link
          rel="canonical"
          href={`https://storicrafter.com${location.pathname}`}
        />
        <meta
          name="description"
          content="Crafting your perfect story beats reading pre-written books! Pick your genre, message and reading level. Personalize with your child’s name. Print, share, enjoy!"
        />
        <meta property="og:title" content={"title"} />
        <meta
          property="og:description"
          content="Crafting your perfect story beats reading pre-written books! Pick your genre, message and reading level. Personalize with your child’s name. Print, share, enjoy!"
        />
        <meta
          property="og:url"
          content={`https://storicrafter.com${location.pathname}`}
        />
      </Helmet>
      <div className="min-h-screen bg-primary pb-7 my-4 lg:my-8">
        <div className="bg-primary">
          {/* <div className="flex justify-start pt-3 pb-[50px] lg:pb-[70px] lg:pt-14 pl-8 lg:pl-[88px]">
            <img
              src={Symbol}
              alt="StoriCrafter logo"
              className="cursor-pointer h-[106px] w-[182px] lg:h-[160px] lg:w-[280px]"
              onClick={() => (window.location.href = "/")}
            />
          </div> */}
          <div className="bg-[#EAC18B] flex items-center pl-8 lg:pl-[220px] py-7 -mx-9 lg:-mx-[88px]">
            <img
              src={genreImage}
              alt="Genere Icon"
              className="h-[62px] w-[62px] lg:h-[125px] lg:w-[125px] mr-3 rounded-lg shadow-storyshadow"
            />
            <div className="text-xl	lg:text-3xl font-bold text-genre">
              {story?.genre}
            </div>
          </div>
          <div className="mb-[44px] lg:mb-[96px] pt-14 lg:pt-[70px] px-9 lg:px-[220px] text-white">
            <div className="text-xl lg:text-[32px] mb-4 lg:mb-6 font-semibold text-start">
              {title}
            </div>
            <div className="text-sm lg:text-base font-light text-justify whitespace-pre-line">
              {story?.content.substring(story?.content.indexOf("\n\n") + 1)}
            </div>
          </div>
        </div>
        {/* <div className="flex flex-col items-center lg:flex-row lg:justify-center gap-7 m-auto px-[98px]">
          <div
            onClick={() => (window.location.href = "/")}
            className="w-full md:w-1/2 lg:w-1/3 bg-white cursor-pointer py-3 font-medium rounded-lg bg-gradient-to-r from-[#FF7844] to-[#FF956D] text-sm lg:text-base"
          >
            Craft another!
          </div>
          <CopyToClipboard text={`storicrafter.com/stori/${story?.id}`}>
            <div
              onClick={shareStory}
              className="w-full md:w-1/2 lg:w-1/3 bg-white cursor-pointer py-3 font-medium rounded-lg bg-gradient-to-r from-[#FF7844] to-[#FF956D] text-sm lg:text-base"
              data-tooltip-id="my-tooltip"
            >
              Share
            </div>
          </CopyToClipboard>
          <Tooltip
            id="my-tooltip"
            content="Copied!"
            style={{ backgroundColor: "rgb(220 237 118)", color: "#222" }}
            openOnClick={true}
          />
        </div>
        <div className="flex flex-col items-center lg:flex-row lg:justify-center gap-7 m-auto px-[98px]">
          <div className="flex-1"></div>
          <div className="flex-1 text-primary my-2 text-start">
            click on ‘share’ and paste on your email or social media!
          </div>
        </div> */}
        <div className="flex flex-col items-center lg:items-start lg:flex-row lg:justify-center gap-7 m-auto px-[98px]">
          <CopyToClipboard text={`storicrafter.com/stori/${story?.id}`}>
            <div className="flex flex-col lg:order-last w-full md:w-1/2 lg:w-1/3 ">
              <div
                onClick={shareStory}
                className="bg-white cursor-pointer py-3 font-medium rounded-lg bg-gradient-to-r from-[#FF7844] to-[#FF956D] text-sm lg:text-base"
                data-tooltip-id="tooltip"
              >
                Share
              </div>
              <div className="flex flex-col items-center lg:flex-row lg:justify-center gap-7 m-auto">
                {/* <div className="flex-1"></div> */}
                <div className="flex-1 text-primary my-1">
                  click on ‘share’ and paste on your email or social media!
                </div>
              </div>
            </div>
          </CopyToClipboard>
          <div
            onClick={saveToLibrary}
            className="w-full md:w-1/2 lg:w-1/3 bg-white cursor-pointer py-3 font-medium rounded-lg bg-gradient-to-r from-[#FF7844] to-[#FF956D] text-sm lg:text-base"
            data-tooltip-id="save-to-library"
          >
            Save to Library
          </div>
          <div
            onClick={() => (window.location.href = "/")}
            className="w-full md:w-1/2 lg:w-1/3 bg-white cursor-pointer py-3 font-medium rounded-lg bg-gradient-to-r from-[#FF7844] to-[#FF956D] text-sm lg:text-base"
          >
            Craft another!
          </div>
          <Tooltip
            id="tooltip"
            content="Copied!"
            style={{ backgroundColor: "rgb(220 237 118)", color: "#222" }}
            openOnClick={true}
          />
          <Tooltip
            id="save-to-library"
            content="Saved to library!"
            style={{ backgroundColor: "rgb(220 237 118)", color: "#222" }}
            openOnClick={true}
          />
        </div>
      </div>
    </Common>
  );
}
