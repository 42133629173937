import { Helmet } from "react-helmet";
import Common from "../layout/common";

export default function Faq() {
  return (
    <Common>
      <Helmet>
        <title>
          Explore StoriCrafter's FAQs | Delightful Stories for Kids to Sleep
        </title>
        <link rel="canonical" href="https://storicrafter.com/faq" />
        <meta
          name="description"
          content="Discover answers to common questions on StoriCrafter's FAQ page. Immerse your child in enchanting bedtime stories for a peaceful night's sleep."
        />
        <meta
          property="og:title"
          content="Explore StoriCrafter's FAQs | Delightful Stories for Kids to Sleep"
        />
        <meta
          property="og:description"
          content="Discover answers to common questions on StoriCrafter's FAQ page. Immerse your child in enchanting bedtime stories for a peaceful night's sleep."
        />
        <meta property="og:url" content="https://storicrafter.com/faq" />
      </Helmet>
      <div className="mt-20 lg:mt-44 lg:mx-[150px]">
        <h1 className="text-white text-start text-2xl lg:text-[32px] font-semibold lg:font-bold mb-4">
          FAQ
        </h1>
      </div>
      <div className="text-justify text-white lg:mx-[150px] my-6">
        <h2 className="text-xl lg:text-[24px] font-semibold">
          Why is it better to craft my own story than buying a book?
        </h2>
        <div className="my-4">
          For a company like StoriCrafter, which specializes in generating
          stories based on input preferences and includes account management and
          billing features, the most common frequently asked questions (FAQs)
          might include the following:
        </div>
        <div className="text-white text-justify text-sm lg:text-base leading-6 flex flex-col gap-2">
          <div>
            <span className="font-semibold">1. Customization:</span> You get to
            choose the genre and themes, ensuring that the story aligns with the
            values and wisdom you want to share with your children.
          </div>
          <div>
            <span className="font-semibold">2. Personalization:</span> By
            incorporating your child's name and making other tailored changes,
            the story becomes more relatable and engaging for them. This can
            make the storytelling experience much more immersive and memorable.
          </div>
          <div>
            <span className="font-semibold">3. Bonding Experience:</span>{" "}
            Creating a story specifically for your child can strengthen your
            bond. It shows them that you're putting in the effort to craft
            something uniquely for them, making storytime even more special.
          </div>
          <div>
            In short, while prewritten books are wonderful in their own right,
            crafting a personalized story elevates the experience, making it
            more meaningful for both you and your child.
          </div>
        </div>
      </div>
      <div className="text-justify text-white lg:mx-[150px] my-6">
        <h3 className="text-xl lg:text-[24px] font-semibold">
          How does StoriCrafter generate stories?
        </h3>
        <div className="my-4">
          At StoriCrafter, our mission is to turn Storytime into a magical
          journey, uniquely tailored just for your child! While we love and
          appreciate classic books like Harry Potter and Dr. Seuss, our approach
          is a bit different, and here's why:
        </div>
        <div className="text-white text-justify text-sm lg:text-base leading-6 flex flex-col gap-2">
          <div>
            When you share with us some key ingredients - like your child's
            interests, favorite characters, or a special theme - our
            story-making cauldron gets bubbling! We mix these inputs with our
            world-class AI technology, which is like a master storyteller who's
            read thousands of books and knows just what makes a story
            captivating for little listeners.
          </div>
          <div>
            Our secret recipe involves careful prompt engineering. Think of it
            as choosing the perfect spices for a meal. This helps us tailor
            stories that aren't just safe and age-appropriate, but also
            incredibly engaging and full of wonder. It's like crafting a unique
            adventure every time, one that resonates perfectly with your child's
            imagination.
          </div>
          <div>
            But wait, there's more! Our team of story wizards continually tests
            and refines our prompt to continue generating great stories. This
            ensures they are not just fun and exciting, but also nurturing and
            educational. So, each tale you get from StoriCrafter isn't just a
            story; it's a carefully crafted journey of discovery and joy for
            your child. Let's embark on this storytelling adventure together!
          </div>
        </div>
      </div>
      <div className="text-justify text-white lg:mx-[150px] my-6">
        <h3 className="text-xl lg:text-[24px] font-semibold">
          Why do you not carry books such as Harry Potter, Dr Seuss, etc?
        </h3>
        <div className="my-4">
          At StoriCrafter, our mission is to turn Storytime into a magical
          journey, uniquely tailored just for your child! While we love and
          appreciate classic books like Harry Potter and Dr. Seuss, our approach
          is a bit different, and here's why:
        </div>
        <div className="text-white text-justify text-sm lg:text-base leading-6 flex flex-col gap-2">
          <div>
            We believe that every child is a star in their own right, and they
            deserve stories that are as special as they are. Instead of offering
            pre-published books that are one-size-fits-all, we craft tales based
            on your inputs - like your child's name, their beloved pet, their
            favorite toy, or even a fantastical adventure they dream of. This
            way, the stories you share aren't just stories; they become personal
            adventures, where your child is the main character!
          </div>
          <div>
            Our goal is to make YOU the hero in your child's eyes. Imagine the
            sparkle in their eyes when they hear a story that’s all about them
            or their world! It’s not just a story; it's a shared experience, a
            memory that you both will cherish forever.
          </div>
          <div>
            So while we don't carry Harry Potter or Dr. Seuss, we offer
            something even more magical - the chance to bring to life a story
            that's as unique and wonderful as your child. Let's craft these
            special moments together, one personalized story at a time!
          </div>
        </div>
      </div>
      <div className="text-justify text-white lg:mx-[150px] my-6">
        <h3 className="text-xl lg:text-[24px] font-semibold">
          What types of stories can StoriCrafter create, and how can they be
          individualized to my child?
        </h3>
        <div className="my-4">
          Welcome to the enchanting world of StoriCrafter, where every story
          sparkles with imagination and is as unique as your child! Here’s how
          we weave these magical tales:
        </div>
        <div className="text-white text-justify text-sm lg:text-base leading-6 flex flex-col gap-2">
          <div>
            <span className="font-semibold">A Galaxy of Genres:</span> Our
            stories span a wide array of genres. From classic fairy tales that
            whisk your child away to magical kingdoms, to exciting tales
            inspired by the rich cultures of India and China, and many more!
            Whether your child dreams of daring knights, mischievous fairies, or
            wise animals, our story garden is ever-blooming with possibilities.
          </div>
          <div>
            <span className="font-semibold">Values Brought to Life:</span> Each
            story can be a gentle guide, weaving in values like friendship,
            bravery, or kindness. Imagine a tale where sharing or honesty saves
            the day, teaching life’s precious lessons wrapped in a blanket of
            fun.
          </div>
          <div>
            <span>Personalized Characters: </span>This is where the real magic
            happens! Your child can be the protagonist of their own story,
            exploring enchanted worlds or solving thrilling mysteries. Their
            favorite toy, a new pet, or even a dreamed-up companion can join
            them in these adventures. Every story is a mirror reflecting your
            child’s world, making them feel special and celebrated.
          </div>
          <div>
            <span className="font-semibold">Age-Perfect Tales: </span>Our
            stories aren’t just one-size-fits-all. They’re tailor-made to suit
            your child’s age, ensuring the content is just right – engaging
            enough for older kids, yet simple and enchanting for the little
            ones.
          </div>
          <div>
            In the hands of StoriCrafter, every story is a doorway to a world
            where your child’s imagination is the hero. Let’s create these
            unforgettable journeys together, one personalized story at a time!
          </div>
        </div>
      </div>
      <div className="text-justify text-white lg:mx-[150px] my-6">
        <h3 className="text-xl lg:text-[24px] font-semibold">
          Is there a limit to how many stories I can generate?
        </h3>
        <div className="my-4">
          At StoriCrafter, we're all about bringing endless storytime adventures
          to you and your little ones! Here's the scoop on how many stories you
          can create:
        </div>
        <div className="text-white text-justify text-sm lg:text-base leading-6 flex flex-col gap-2">
          <div>
            <span className="font-semibold">
              A World of Stories at Your Fingertips:
            </span>{" "}
            Guess what? There's no strict limit to how many stories you can
            generate! Our magical story machine is ready and raring to go, eager
            to spin tales as often as you'd like.
          </div>
          <div>
            <span className="font-semibold">Perfect for Daily Adventures:</span>{" "}
            While there's no hard cap, our system is ideally designed to conjure
            up a couple of stories each day. It's like having a never-ending
            storybook that opens up to new chapters every day! This way, you and
            your child can look forward to fresh, exciting stories regularly,
            perfect for cozy bedtime tales or afternoon story sessions.
          </div>
          <div>
            <span>Personalized Characters: </span>This is where the real magic
            happens! Your child can be the protagonist of their own story,
            exploring enchanted worlds or solving thrilling mysteries. Their
            favorite toy, a new pet, or even a dreamed-up companion can join
            them in these adventures. Every story is a mirror reflecting your
            child’s world, making them feel special and celebrated.
          </div>
          <div>
            So, feel free to dive into our treasure trove of stories as often as
            you wish. Whether it's one story a night or a couple throughout the
            day, we're here to make each storytime a magical moment with your
            child. Happy storytelling!
          </div>
        </div>
      </div>
      <div className="text-justify text-white lg:mx-[150px] my-6">
        <h4 className="text-xl lg:text-[24px] font-semibold">
          Can I save or export the stories I create?
        </h4>
        <div className="my-4">
          Absolutely, you can keep the magic alive beyond storytime with
          StoriCrafter! Here's how you can save those special tales:
        </div>
        <div className="text-white text-justify text-sm lg:text-base leading-6 flex flex-col gap-2">
          <div>
            <span className="font-semibold">Your Story Treasury:</span> Right
            now, you can easily copy the stories you create and save them onto
            your desktop. Imagine having a treasure chest of personalized tales,
            each one a unique adventure shared with your child!
          </div>
          <div>
            <span className="font-semibold">
              Exciting Features on the Horizon:
            </span>{" "}
            And guess what? We're brewing some enchanting new features to make
            saving your stories even easier and more delightful! Soon, you'll be
            able to effortlessly export and preserve these cherished storytime
            moments with just a click.
          </div>
          <div>
            So go ahead, build your own collection of magical StoriCrafter
            tales, and relive those joyful storytelling experiences anytime you
            wish. Your family’s storybook is just beginning!
          </div>
        </div>
      </div>
      <div className="text-justify text-white lg:mx-[150px] my-6">
        <h4 className="text-xl lg:text-[24px] font-semibold">
          How do I update my billing information?
        </h4>
        <div className="my-4">
          Updating your billing information with StoriCrafter is as easy as
          turning the page in a storybook! Here's what you can do:
        </div>
        <div className="text-white text-justify text-sm lg:text-base leading-6 flex flex-col gap-2">
          <div>
            <span className="font-semibold">Send Us a Message:</span> Just drop
            us an email at hello@storicrafter.com. Our friendly team is ready to
            assist you with any billing updates you need. Think of us as your
            helpful story assistants, always there to ensure your experience is
            as smooth and enjoyable as the stories we create!
          </div>
          <div>
            We're here to make every part of your StoriCrafter journey,
            including the practical bits, as seamless and delightful as
            possible. So, whenever you're ready to update your billing details,
            just send us a note, and we'll take care of the rest. Happy
            storytelling!
          </div>
        </div>
      </div>
      <div className="text-justify text-white lg:mx-[150px] my-6">
        <h4 className="text-xl lg:text-[24px] font-semibold">
          What should I do if I forget my login credentials?
        </h4>
        <div className="my-4">
          No worries if you've forgotten your login credentials for StoriCrafter
          – it happens to the best of us! Here's a simple and cheerful way to
          get back on track:
        </div>
        <div className="text-white text-justify text-sm lg:text-base leading-6 flex flex-col gap-2">
          <div>
            **Just Drop Us a Line**: Send a quick email to
            hello@storicrafter.com, and our friendly team will be right there to
            help you out. Think of it as sending a message in a bottle, and
            we're the helpful sea creatures ready to deliver the help you need!
          </div>
          <div>
            We understand that a forgotten password or username can be a little
            hiccup in your storytelling journey, so we're here to smooth things
            out quickly and get you back to the magical world of StoriCrafter
            tales. So, whenever you're in a pinch, just reach out, and we'll
            guide you back to your story crafting adventures!
          </div>
        </div>
      </div>
      <div className="text-justify text-white lg:mx-[150px] my-6">
        <h5 className="text-xl lg:text-[24px] font-semibold">
          Is my personal information and story data secure on StoriCrafter?
        </h5>
        <div className="my-4">
          Absolutely! At StoriCrafter, safeguarding your personal information
          and story data is as important to us as creating enchanting stories.
          Here's how we ensure your peace of mind:
        </div>
        <div className="text-white text-justify text-sm lg:text-base leading-6 flex flex-col gap-2">
          <div>
            We employ industry-standard practices to protect your data, treating
            it with as much care as we do in crafting your stories. Think of it
            as a magical shield guarding your personal information against
            dragons and trolls!
          </div>
          <div>
            For more details on how we keep your data safe and sound, take a
            peek at our Privacy Policy. It's like our spellbook of promises,
            detailing how we handle and cherish your information.
          </div>
          <div>
            Rest assured, your adventures with StoriCrafter are not only filled
            with imagination and fun but also backed by our commitment to
            keeping your data secure and private. So you can focus on the joy of
            storytelling, worry-free!
          </div>
        </div>
      </div>
      <div className="text-justify text-white lg:mx-[150px] my-6">
        <h5 className="text-xl lg:text-[24px] font-semibold">
          Can I share my stories with others?
        </h5>
        <div className="my-4">
          Absolutely! Sharing your StoriCrafter tales with others is not just
          allowed, it's encouraged! Here's how you can spread the magic:
        </div>
        <div className="text-white text-justify text-sm lg:text-base leading-6 flex flex-col gap-2">
          <div>
            <span className="font-semibold">Spread the Joy: </span>Feel free to
            share the wonderful stories you create with friends and family.
            Imagine the smiles and wonder as they hear a tale crafted by you,
            starring your little one!
          </div>
          <div>
            <span className="font-semibold">Social Media Stars: </span>Go ahead
            and post your StoriCrafter stories on social media. It's a fantastic
            way to show the world your storytelling flair and the unique tales
            you and your child have brought to life.
          </div>
          <div>
            <span className="font-semibold">Share Your Experience: </span>While
            you're sharing your stories, why not share your StoriCrafter
            experience too? Your journey could inspire other parents and
            guardians to embark on their own storytelling adventures!
          </div>
          <div>
            Remember, every story you tell is a gift of imagination and joy. By
            sharing them, you're not just spreading stories, but also the wonder
            and magic that comes with them. Happy sharing!
          </div>
        </div>
      </div>
      <div className="text-justify text-white lg:mx-[150px] my-6">
        <h5 className="text-xl lg:text-[24px] font-semibold">
          How do I cancel my subscription or delete my account?
        </h5>
        <div className="my-4">
          We're always here to help, even when it's time to say goodbye. If you
          need to cancel your subscription or delete your account with
          StoriCrafter, here's how you can do it:
        </div>
        <div className="text-white text-justify text-sm lg:text-base leading-6 flex flex-col gap-2">
          <div>
            <span className="font-semibold">Send a Farewell Note: </span>Just
            drop us a quick email at hello@storicrafter.com. Our friendly team
            will assist you with the process, ensuring it's as smooth and
            hassle-free as possible.
          </div>
          <div>
            <span className="font-semibold">Timely Assistance: </span>We'll make
            sure to help you cancel your account before your next billing cycle.
            It's really that simple – no complicated steps or mazes to navigate!
          </div>
          <div>
            While we're sad to see you leave the StoriCrafter family, we
            understand that every story has its ending. And remember, should you
            ever wish to return and reignite the magic of storytelling, our door
            is always open for your next adventure!
          </div>
        </div>
      </div>
    </Common>
  );
}
