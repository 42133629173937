export default function FacebookIcon({ className, ...props }) {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      {...props}
    >
      <rect width="40" height="40" rx="20" fill="#27252B" />
      <path
        d="M10.5 20C10.5 15.5217 10.5 13.2825 11.8912 11.8912C13.2825 10.5 15.5217 10.5 20 10.5C24.4783 10.5 26.7175 10.5 28.1088 11.8912C29.5 13.2825 29.5 15.5217 29.5 20C29.5 24.4783 29.5 26.7175 28.1088 28.1088C26.7175 29.5 24.4783 29.5 20 29.5C15.5217 29.5 13.2825 29.5 11.8912 28.1088C10.5 26.7175 10.5 24.4783 10.5 20Z"
        stroke="#FFFFFF"
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
      <path
        d="M25 16.75C25.4142 16.75 25.75 16.4142 25.75 16C25.75 15.5858 25.4142 15.25 25 15.25V16.75ZM24 16V16.75V16ZM19.25 29.5C19.25 29.9142 19.5858 30.25 20 30.25C20.4142 30.25 20.75 29.9142 20.75 29.5H19.25ZM18 21.25C17.5858 21.25 17.25 21.5858 17.25 22C17.25 22.4142 17.5858 22.75 18 22.75V21.25ZM23 22.75C23.4142 22.75 23.75 22.4142 23.75 22C23.75 21.5858 23.4142 21.25 23 21.25V22.75ZM25 15.25H24V16.75H25V15.25ZM19.25 20V29.5H20.75V20H19.25ZM24 15.25C23.0784 15.25 22.3117 15.2484 21.7055 15.3299C21.0777 15.4143 20.5109 15.6 20.0555 16.0555L21.1161 17.1161C21.2464 16.9858 21.4439 16.8786 21.9054 16.8165C22.3884 16.7516 23.036 16.75 24 16.75V15.25ZM20.75 20C20.75 19.036 20.7516 18.3884 20.8165 17.9054C20.8786 17.4439 20.9858 17.2464 21.1161 17.1161L20.0555 16.0555C19.6 16.5109 19.4143 17.0777 19.3299 17.7055C19.2484 18.3117 19.25 19.0784 19.25 20H20.75ZM18 22.75H23V21.25H18V22.75Z"
        fill="#FFFFFF"
      />
    </svg>
  );
}
