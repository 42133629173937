import { createContext, useContext, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useLocalStorage } from "../hooks/useLocalStorage";
import { setAuth } from "../libs/set-auth";
import instance from "../libs/axios";

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useLocalStorage("user", null);
  const navigate = useNavigate();

  const login = async (data) => {
    try {
      const {
        data: { token },
      } = await instance.post("/auth/login", data);
      setUser(token);
      setAuth(token);
      navigate("/home", { replace: true });
    } catch (error) {
      console.log(error);
    }
  };

  const logout = () => {
    setUser(null);
    setAuth();
    localStorage.setItem("paid", JSON.stringify(false));
    localStorage.setItem("payload", JSON.stringify(null));
    navigate("/", { replace: true });
  };

  const value = useMemo(
    () => ({
      user,
      login,
      logout,
    }),
    [user]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext);
};
