import { useState } from "react";
import { cn } from "../libs/utils";
import ChevDown from "../assets/icons/chevDown";
const options = [3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

export default function Select({ className, onChange }) {
  const [open, openList] = useState(false);
  const [selected, setSelected] = useState(0);
  return (
    <div
      className={cn(
        "py-4 px-5 w-full md:w-1/2 lg:w-1/3 m-auto mb-40 border border-2 lg:border-4 border-links rounded-lg shadow-drop cursor-pointer",
        className
      )}
      onClick={() => openList(!open)}
    >
      <div className="flex items-center justify-between text-white">
        <div className="font-semibold">
          {selected ? `${selected}` : "Select the age"}
        </div>
        <ChevDown
          className={cn(
            "cursor-pointer transition duration-300 ease-in-out",
            open && "-rotate-180"
          )}
        />
      </div>
      <div className={cn("text-white text-start", open && "mt-2")}>
        {open &&
          options.map((item, index) => (
            <div
              key={index}
              className="py-2 cursor-pointer font-semibold"
              onClick={() => {
                onChange(item);
                setSelected(item);
                openList(false);
              }}
            >
              {item}
            </div>
          ))}
      </div>
    </div>
  );
}
