import { cn } from "../../libs/utils";
export default function ChevDown({ className, stroke = "#CECCCC", ...props }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="8"
      viewBox="0 0 16 8"
      fill="none"
      className={cn(className)}
    >
      <path
        d="M1.78223 1L8.38558 7L14.9889 1"
        stroke={stroke}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
