import { cn } from "../../libs/utils";

export default function UserProfile({ className, ...props }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="17"
      viewBox="0 0 18 17"
      fill="none"
      className={cn(className)}
      {...props}
    >
      <path
        d="M17.2883 15.7022C16.0165 13.5034 14.0565 11.9267 11.7691 11.1793C12.9006 10.5057 13.7796 9.47942 14.2713 8.25793C14.763 7.03644 14.8401 5.68732 14.4908 4.41776C14.1415 3.1482 13.3851 2.02839 12.3378 1.23031C11.2905 0.43223 10.0102 0 8.69345 0C7.37672 0 6.09639 0.43223 5.04908 1.23031C4.00178 2.02839 3.2454 3.1482 2.89612 4.41776C2.54683 5.68732 2.62395 7.03644 3.11562 8.25793C3.6073 9.47942 4.48634 10.5057 5.61777 11.1793C3.33042 11.9259 1.37043 13.5026 0.0985725 15.7022C0.0519311 15.7783 0.0209942 15.8629 0.00758706 15.9511C-0.00582004 16.0393 -0.00142575 16.1293 0.0205107 16.2158C0.0424471 16.3022 0.0814814 16.3834 0.13531 16.4546C0.189139 16.5257 0.256672 16.5854 0.333925 16.63C0.411178 16.6746 0.496586 16.7033 0.58511 16.7144C0.673634 16.7255 0.76348 16.7187 0.849348 16.6945C0.935216 16.6703 1.01537 16.6291 1.08507 16.5735C1.15477 16.5178 1.21262 16.4487 1.25519 16.3703C2.82852 13.6512 5.60942 12.0278 8.69345 12.0278C11.7775 12.0278 14.5584 13.6512 16.1317 16.3703C16.1743 16.4487 16.2321 16.5178 16.3018 16.5735C16.3715 16.6291 16.4517 16.6703 16.5376 16.6945C16.6234 16.7187 16.7133 16.7255 16.8018 16.7144C16.8903 16.7033 16.9757 16.6746 17.053 16.63C17.1302 16.5854 17.1978 16.5257 17.2516 16.4546C17.3054 16.3834 17.3445 16.3022 17.3664 16.2158C17.3883 16.1293 17.3927 16.0393 17.3793 15.9511C17.3659 15.8629 17.335 15.7783 17.2883 15.7022ZM4.01688 6.01501C4.01688 5.09008 4.29115 4.18591 4.80502 3.41685C5.31889 2.64779 6.04927 2.04838 6.9038 1.69442C7.75834 1.34046 8.69864 1.24785 9.60581 1.4283C10.513 1.60874 11.3463 2.05414 12.0003 2.70817C12.6543 3.36221 13.0997 4.19549 13.2802 5.10266C13.4606 6.00983 13.368 6.95013 13.014 7.80466C12.6601 8.6592 12.0607 9.38958 11.2916 9.90345C10.5226 10.4173 9.61839 10.6916 8.69345 10.6916C7.45356 10.6903 6.26482 10.1971 5.38808 9.32039C4.51134 8.44365 4.0182 7.25491 4.01688 6.01501Z"
        fill="#6D6D6D"
      />
    </svg>
  );
}
