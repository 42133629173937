import { cn } from "../libs/utils";
import Header from "./header";
import Footer from "./footer";

export default function Common({ children, className, ...props }) {
  return (
    <div
      className={cn(
        "min-h-screen bg-primary px-9 pt-3 lg:px-[88px] lg:pt-14",
        className
      )}
      {...props}
    >
      <Header />
      {children}
      <Footer />
    </div>
  );
}
