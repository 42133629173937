export default function InstagramIcon({ className, ...props }) {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      {...props}
    >
      <rect width="40" height="40" rx="20" fill="#27252B" />
      <path
        d="M10.5 20C10.5 15.5217 10.5 13.2825 11.8912 11.8912C13.2825 10.5 15.5217 10.5 20 10.5C24.4783 10.5 26.7175 10.5 28.1088 11.8912C29.5 13.2825 29.5 15.5217 29.5 20C29.5 24.4783 29.5 26.7175 28.1088 28.1088C26.7175 29.5 24.4783 29.5 20 29.5C15.5217 29.5 13.2825 29.5 11.8912 28.1088C10.5 26.7175 10.5 24.4783 10.5 20Z"
        stroke="#FFFFFF"
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
      <path
        d="M24.5 20C24.5 22.4853 22.4853 24.5 20 24.5C17.5147 24.5 15.5 22.4853 15.5 20C15.5 17.5147 17.5147 15.5 20 15.5C22.4853 15.5 24.5 17.5147 24.5 20Z"
        stroke="#FFFFFF"
        strokeWidth="1.5"
      />
      <path
        d="M25.5078 14.5L25.4988 14.5"
        stroke="#FFFFFF"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
