import { useEffect, useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import { marked } from "marked";
import { Helmet } from "react-helmet";
import Common from "../layout/common";
import { blogs } from "../libs/const";

export default function BlogDetail() {
  const [markdown, setMarkdown] = useState();
  const params = useParams();
  const location = useLocation();

  const blog = blogs.filter((item) => item.id == params.id)[0];

  useEffect(() => {
    async function loadMarkdown() {
      const res = await fetch(blog.markdown);
      const text = await res.text();
      setMarkdown(await marked(text));
    }
    loadMarkdown();
  }, []);

  return (
    <Common>
      <Helmet>
        <title>{blog.metaTitle}</title>
        <link
          rel="canonical"
          href={`https://storicrafter.com${location.pathname}`}
        />
        <meta name="description" content={blog.metaDesc} />
        <meta property="og:title" content={blog.metaTitle} />
        <meta property="og:description" content={blog.metaDesc} />
        <meta
          property="og:url"
          content={`https://storicrafter.com${location.pathname}`}
        />
      </Helmet>
      <h1 className="text-white text-center text-xl mt-20 lg:mt-44 lg:text-[32px] font-medium lg:font-bold">
        Blog Posts & Articles
      </h1>
      <h2 className="text-white mt-5 mb-10 text-2xl font-bold">{blog.title}</h2>
      <div className="flex m-auto flex-1 lg:justify-center lg:mx-[195px] aspect-w-16 aspect-h-9">
        <img src={blog.img} alt="blog_detail_image" className="rounded-sm" />
      </div>
      <div className="my-10 lg:my-[85px] lg:mx-[210px] text-justify text-introduction whitespace-pre-line">
        <article dangerouslySetInnerHTML={{ __html: markdown }}></article>
      </div>
    </Common>
  );
}
